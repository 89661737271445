import React from 'react'
import { AuthProvider } from '../contexts/AuthContext'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import PrivateRoute from './routing/PrivateRoute'

//import pages
import SignUpPage from './pages/SignUpPage'
import LogInPage from './pages/LogInPage'
import LeaguesPage from './pages/LeaguesPage'
import LeaguePage from './pages/LeaguePage'
import SeasonPage from './pages/SeasonPage'
import CreateMatchPage from './pages/CreateMatchPage'
import LeagueOrgsPage from './pages/LeagueOrgsPage'
import VenuesPage from './pages/VenuesPage'
//import RefereesPage from './pages/RefereesPage'


function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/sign-up" element={<SignUpPage/>}/>
          <Route path="/log-in" element={<LogInPage/>}/>
          <Route path='/' element={<PrivateRoute/>}>
            <Route path="/" element={<LeaguesPage/>}/>
            <Route path="/league-orgs/:leagueOrgId/leagues/:leagueId" element={<LeaguePage/>}/>
            <Route path="/league-orgs/:leagueOrgId/leagues/:leagueId/seasons/:seasonId" element={<SeasonPage/>}/>
            <Route path="/league-orgs/:leagueOrgId/leagues/:leagueId/seasons/:seasonId/create-match" element={<CreateMatchPage/>}/>
            <Route path="/league-orgs" element={<LeagueOrgsPage/>}/>
            <Route path="/venues" element={<VenuesPage/>}/>
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;